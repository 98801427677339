/**
 * 引用来源相关函数
 *
 *  2021-04-22  wuzhipeng
 */

import resourceConst from '@/const/resourceConst'
import { setCache, getCache } from '@/utils/cacheUtil'
import { categoryList } from '@/api/dkm/resource'
import { selectReferenceInfo } from '@/api/dkm/resource'

import { handleTree } from '@/utils/common'

const { RESOURCE_CATEGORY,RESOURCE_CATEGORY_TREE } = resourceConst


//从缓存获取引用资源类型树形结构
export async function getResourceCategoryTree() {
  //let categoryTree = getCache(RESOURCE_CATEGORY_TREE)
  let categoryTree= undefined
  if (categoryTree == undefined) {
    let categoryData
    await categoryList().then(response => {
      categoryData=response.data
      setCache(RESOURCE_CATEGORY, response.data)
    })
    categoryTree=handleTree(categoryData,'id','pid')
    setCache(RESOURCE_CATEGORY_TREE, categoryTree)
  }
  return categoryTree
}

//从缓存获取引用资源类型
export async function getResourceCategory() {
  let category = getCache(RESOURCE_CATEGORY)
  if (category == undefined) {
    await queryCategoryList()
  }
  return getCache(RESOURCE_CATEGORY)
}

// 根调用接口查询资源类型
async function queryCategoryList() {
  await categoryList().then(response => {
    setCache(RESOURCE_CATEGORY, response.data)
  })
}

// 根据表名称、id、字段名查询来源引用信息
export async function getReferenceInfo(sourceTable,sourceTableId,sourceTableField){
  let  result=[]
  //调用接口获取引用资源信息
  await selectReferenceInfo(sourceTable,sourceTableId, sourceTableField).then(response => {
    result= response.data;
  })
  return result;
}
