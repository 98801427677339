import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

// 根据资源类型id 和 资源名称  模糊查询资源名称列表
export function resourceNameQuery(resourceTypeId,queryStr) {
  return request({
    url: '/dkm/resource/selectResourceNameList',
    method: 'post',
    params: {categoryId:resourceTypeId,queryStr:queryStr}
  })
}

// 编辑资源类型 新增或修改
export function editResourceCategory(params) {
  return request({
    url: '/dkm/resource/editResourceCategory',
    method: 'post',
    data: params
  })
}

// 查看资源明细
export function resourceCategoryInfo(resourceCategoryId) {
  return request({
    url: '/dkm/resource/resourceCategoryInfo',
    method: 'get',
    params: {resourceCategoryId:resourceCategoryId}
  })
}


// 删除资源
export function delResourceCategory(resourceCategoryId) {
  return request({
    url: '/dkm/resource/delResourceCategory',
    method: 'post',
    params: {resourceCategoryId:resourceCategoryId}
  })
}


// 获取资源属性字典
export function getResourcePropertyDict() {
  return request({
    url: '/dkm/resource/getResourcePropertyDict',
    method: 'get',
  })
}

// 查询引用的资源列表
export function selectReferenceInfo(sourceTable,sourceTableId,sourceTableField) {
  return request({
    url: '/dkm/resource/selectReferenceInfo',
    method: 'post',
    params: {sourceTable:sourceTable,sourceTableId:sourceTableId,sourceTableField:sourceTableField}
  })
}

// 查询资源类型列表
export function categoryList() {
  return request({
    url: '/dkm/resource/categoryList',
    method: 'post',
  })
}

// 保存引用资源列表
export function saveOrUpdateInfoList(infoList, sourceTableId, sourceTable) {
  return request({
    url: '/dkm/resource/saveOrUpdateInfoList',
    method: 'post',
    data: {infoList:infoList,sourceTableId:sourceTableId,sourceTable:sourceTable}
  })
}

