/**
 * 引用来源相关常量
 *
 *  2021-04-22  wuzhipeng
 */

module.exports = {
  /* 资源类型*/
  RESOURCE_CATEGORY: 'resource_category',
  /* 资源类型树结构*/
  RESOURCE_CATEGORY_TREE: 'resource_category_tree',

}
